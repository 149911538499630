import * as Style from "../styles/Style";
import styled, { keyframes } from "styled-components";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import logoKittyMatrixAnimated from "../assets/kitty-matrix-animated-3.gif";
import imgDitherBackgroundHeader from "../assets/pixeltilebg.png";

// rendering engine
import imgArrowRight from "../assets/rendering_engine/arrow-right.png";
import imgStep1 from "../assets/rendering_engine/step-1.png";
import imgStep2 from "../assets/rendering_engine/step-2.png";
import imgStep3 from "../assets/rendering_engine/step-3.png";

import imgKittiesAnimatedHeaderRow from "../assets/dancestrip.gif";
import imgKittiesRowSmall from "../assets/cat-in-row.png";
import imgKittiesIconSmall from "../assets/kitty-icon-small.png";
import imgKittyIcon from "../assets/kitty-about.png";
import imgKittyPfpExample from "../assets/cycle3.gif";
import imgKittyAbout from "../assets/kitty-crew-stars.png";
import imgDailoFrog from "../assets/dailofrog.png";
import imgGoopGoop from "../assets/goopgoop.jpg";

import imgAnimatedCatDJPlaying from "../assets/musicplayer/PlayMusic.gif";
import imgAnimatedCatDJIdle from "../assets/musicplayer/PressPlay.gif";

// faq
import imgKittiesThumbsUp from "../assets/faq/thumbsup.png";
import imgColorSchemeNES from "../assets/faq/nes-colors.png";
import imgFAQChonkers from "../assets/faq/chonkers.png";
import imgFAQJapaneseNo from "../assets/faq/japanese-no.jpg";
import imgFAQRainbowGif from "../assets/catrainbow2.gif";


const links = {
  twitter: "https://twitter.com/dailofrog",
  twitterGoop: "https://twitter.com/goopgoop_art",
  discord: "https://discord.gg/KBAbk2a4PM",
  linktree: "https://linktr.ee/dailofrog",
  magicEden: "https://magiceden.io/ordinals/marketplace/kibodo",
  provenance: "https://ordi.io",
  tinyBasedFrogs: "https://www.tinybasedfrogs.xyz",
  memoryBlocks: "https://www.memoryblocks.art/",
  blastRocks: "https://www.blastrocks.art/",
  inscribeNow: "https://inscribenow.io/collections/63dceff35794998e",

  opCat: "https://hashrateindex.com/blog/a-guide-to-op_cat/",
  btcHalvening: "https://www.bitcoinblockhalf.com/",

  inscriptionParent: "https://ordiscan.com/inscription/351f3ab683cb148034422f5888f9611adff42c100d381d56a183687cfeb3574fi0",
  inscriptionEncodedDataset: "https://ordiscan.com/inscription/445cff5835dd0c187a09800b8d52e454aaf7cc4c4d34771b98ba5eb0334df400i0",
  inscriptionRenderEngine: "https://ordiscan.com/inscription/b698ee7dec2972a0db3fa0d7a5a3c1bb332d02ddfd0cfd68bd857ba1147506fei0",
};

function HrefLink(item, title) {
  return (
    <a href={links[item]} target="blank">
      {title}
    </a>
  );
}

function RotatedLineStart() {
  const StyleLine = styled.span`
    color: black;
    font-family: "Press Start 2P", monospace, sans-serif;
    font-size: 24px;
    position: relative;
    top: 4px;
  `;
  return <StyleLine>|</StyleLine>;
}

const ResponsiveImage = styled.img`
      max-width: 100%;
      height: auto;
`;

function HeroSection() {
  const HeroText = styled.div`
    color: blue;
    text-align: center;

    h1 {
      font-size: 3em;
    }

    h2 {
      font-size: 1.5em;
    }

    z-index: 999;
  `;

  const ImageCatDJ = styled.div`
    position: absolute;
    top: -32px;
    left: 20px;

    img {
      width: 2.5em;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);


      @media (max-width: 650px) {
        display:none;
      }
    }
  `;

  const HeroKittyImageRow = styled.div`
    position: absolute;
    bottom: 0;
    justify-content: center; /* Center horizontally */
    text-align:center;
    overflow:hidden;
    width: 100%; // Set the desired width

    img {
      width:90%;
      min-width:865px;
    }
`;

  const BackgroundDiv = styled.div`
    display: flex; /* Enables Flexbox */
    justify-content: center; /* Centers content horizontally */
    align-items: center;
    width: 100%; // Full width of the container
    height: 100%; // Full height of the container
    background-image: url(${imgDitherBackgroundHeader}); // Replace with your image path
    background-repeat: repeat-x; // Tile the background image
    background-size: auto 50%;
  `;

  return (
    <Style.Section bg="#d8f878">
      <Style.FixedHeightContainer style={{ minHeight: "17em" }}>
        <BackgroundDiv>
          <ImageCatDJ>
            <img src={imgAnimatedCatDJPlaying} />
          </ImageCatDJ>
          <Style.Navbar>
            {HrefLink("magicEden", "MAGIC EDEN")} /{" "}
            {HrefLink("discord", "DISCORD")} /{" "}
            {HrefLink("twitter", "TWITTER")}
          </Style.Navbar>

          <HeroText>
            <div>
              <h1>
                KIBODO
                <br />
                KITTY
                <br />
                CLUB
              </h1>
              <h2>
                <Style.Japanese>キーボードキティクラブ</Style.Japanese>
              </h2>
            </div>
          </HeroText>

          <HeroKittyImageRow>
            <img src={imgKittiesAnimatedHeaderRow} />
          </HeroKittyImageRow>

          <Style.RotatedTextLeft top="46%">
            DAILOFROG /{" "}
            <Style.JapaneseSideways>ビットコイン</Style.JapaneseSideways>
          </Style.RotatedTextLeft>

          <Style.RotatedTextRight top="46%">
            <RotatedLineStart /> ORDINALS COLLECTION /{" "}
            <Style.JapaneseSideways>ビットコイン</Style.JapaneseSideways>
          </Style.RotatedTextRight>
        </BackgroundDiv>
      </Style.FixedHeightContainer>
    </Style.Section>
  );
}

function AboutSection() {
  const ImageHero = styled.div`
    width: 40%;
    //background-color:blue;
    margin-right: 1.5em;

    img {
      max-width: 100%;
      height: auto;
    }

    @media (max-width: 950px) {
      width: 60%;
      margin-bottom: 2em;
    }
  `;

  const Description = styled.div`
    width: 40%;
    margin: 0 10px;

    @media (max-width: 950px) {
      width: 80%;
      text-align: center;
    }

    p {
      font-size: 0.5em;
      line-height: 1.2em;
    }

    h3 {
      font-weight: normal;
      font-size: 1.25em;
    }

    h4 {
      font-size: 0.75em;
    }

    a {
      color: blue;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  `;

  const MintInfo = styled.div`
    background-color: #8cffba;
    box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 0.3);


    
    h3 {
      color: white;
      background-color:#4232ff;
      margin: 0;
      font-size:0.5em;
      text-transform: uppercase;
      line-height:1em;
      padding: 0.7em;
    }

    p { 
      padding: 1em;
    }

    @media (max-width: 950px) {

      img {
        display:none;
      }
    }
  `;

  const JapaneseFooter = styled.div`
    font-size: 12px;
    margin-top: 1em;
    padding-top: 5px;
    color: #aaa;
  `;

  return (
    <Style.Section bg="white" id="aboutsection">
      <Style.ScrollingSectionHeader bg="#1254FF">
        <Style.ScrollingContent>
          <h2>
            MINT SOON / MINT SOON / MINT SOON / MINT SOON / MINT SOON / MINT
            SOON / MINT SOON / MINT SOON / MINT SOON / MINT SOON / MINT SOON /
            MINT SOON / MINT SOON / MINT SOON / MINT SOON / MINT SOON /{" "}
          </h2>
        </Style.ScrollingContent>
      </Style.ScrollingSectionHeader>

      <Style.FixedHeightContainer height="auto" style={{ padding: "3em 0" }}>
        <ImageHero>
          <img src={imgKittyAbout} />
        </ImageHero>
        <Description>
          <h3>
            ANIMATED.
            <br />
            FLUFFY.
            <br />
            BITCORN.
            <br />
            CHONKERS.
          </h3>
          <br />
          <h4>BY {HrefLink("twitter", "DAILOFROG")}</h4>
          <br />
          <p>Fully onchain animated Ordinals chonkers, jammin' out to the Bitcoin halvening and celebrating {HrefLink("opCat", "OP_CAT")}!</p>
          <br />
          <p>
            Inspired by classic Japanese NES games, these fluffy generative lil kitties dance lovingly on your keyboard.
          </p>
          <br />

          <MintInfo>
            <h3>Mint Info</h3>
            <Style.Button target="_blank" href={links.magicEden}>VIEW COLLECTION</Style.Button>
            <p>
              ◼ SUPPLY: 690 (MINTED OUT)
              {/*<br />◼ PRICE: 0.0025 BTC*/}
              <br />◼ RELEASED: APR 11, 2024
              <br />◼ TYPE: ORDINALS (BTC)
              <br />◼ METADATA: ONCHAIN 
              <br />◼ PARENT-CHILD PROV
            </p>
          </MintInfo>

          <JapaneseFooter>
            <Style.Japanese>
              このキーボードの子猫たちは本当に可愛いです
            </Style.Japanese>
          </JapaneseFooter>
        </Description>

        <Style.RotatedTextLeft>
          <Style.JapaneseSideways>
            このキーボードの子猫たちは本当に可愛いです
          </Style.JapaneseSideways>
        </Style.RotatedTextLeft>

        <Style.RotatedTextRight>
          <RotatedLineStart /> KIBODO KITTY CLUB /{" "}
          <Style.JapaneseSideways>キーボード</Style.JapaneseSideways>
        </Style.RotatedTextRight>
      </Style.FixedHeightContainer>
    </Style.Section>
  );
}

function ExampleSection() {
  const scrollDiagonally = keyframes`
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 512px 384px; // Adjust these values based on desired speed and direction
    }
  `;

  const AnimatedBackgroundDiv = styled.div`
    display: flex; /* Enables Flexbox */
    justify-content: center; /* Centers content horizontally */
    align-items: center;
    width: 100%; // Full width of the container
    height: 100%; // Full height of the container
    background-image: url(${logoKittyMatrixAnimated}); // Replace with your image path
    background-repeat: repeat; // Tile the background image
    //background-size: 40%; // Scale the background image by 2x
     background-size: 512px 384px; // Set a fixed size for the background image
    animation: ${scrollDiagonally} 15s linear infinite; // Adjust time for speed
  `;

  const BlackBox = styled.div`
    background-color: #fba510;
    color: black;
    //padding: 1em 0.75em;
    font-size: 0.5em;

    width: 40%;
    //justify-content: center; /* Centers content horizontally */
    //align-items: center;

    box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 0.3);
    border: 3px solid black;

    @media (max-width: 850px) {
      width: 60%;
    }

    h3 {
      background-color: black;
      padding: 0.5em;
      color: white;
    }
  `;

  const ImageHero = styled.div`
    width: 100%;
    margin-right: 1.5em;.
    text-align:center;

    @media (max-width: 950px) {
      width: 100%;
      margin-bottom:1em;
    }

    img {
      box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.1); /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
      width: 100%;
      height:auto;
      border-radius: 1em;
      border: 3px solid black;
    }

  `;

  return (
    <Style.Section>
      <Style.ScrollingSectionHeader bg="#fba510">
        <Style.ScrollingContent>
          <h2>
            ORDINALS / ORDINALS / ORDINALS / ORDINALS / ORDINALS / ORDINALS /
            ORDINALS / ORDINALS / ORDINALS / ORDINALS / ORDINALS / ORDINALS /
            ORDINALS / ORDINALS / ORDINALS / ORDINALS / ORDINALS / ORDINALS /{" "}
          </h2>
        </Style.ScrollingContent>
      </Style.ScrollingSectionHeader>

      <Style.FixedHeightContainer style={{ minHeight: "400px" }}>
        <AnimatedBackgroundDiv>
          <BlackBox>
            <h3>FLUFFY + ONCHAIN</h3>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", padding: "0.5em"}}>
            <ImageHero>
              {" "}
              <img src={imgKittyPfpExample} />{" "}
            </ImageHero>
            <div>
              <p>Animated generative chonkers rendered using onchain recursive modules.</p>
            </div>
            </div>
          </BlackBox>
        </AnimatedBackgroundDiv>
      </Style.FixedHeightContainer>
    </Style.Section>
  );
}

function FAQSection() {
  const FAQContainer = styled.div`
    //display: flex; /* Enables Flexbox */
    //flex-wrap: wrap; /* Allows items to wrap onto the next line */
    //justify-content: center; /* Aligns items to the start horizontally */
    //align-items: flex-start; /* Aligns items to the start vertically */
    //align-items: stretch; /* Stretch items to fill the container */
    //align-content: stretch; /* Adjust spacing between lines */
    padding: 2em 2em;
    font-size: 1em;

    @media (max-width: 720px) {
      font-size:2em;
      padding: 0.5em;
    }

    a {
      color: blue;
      text-decoration: underline;
    }

    a:hover {
      background-color: yellow;
      color: black;
    }
  `;

  const FAQItem = styled.div`
    background-color: white;
    border: 3px solid blue;
    color: black;
    margin: 10px;
    padding: 0.5em;
    z-index: 2;

    box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 0.3);

    p {
      margin-top: 2em;
      font-size: 0.25em;
      line-height: 1.3em;
    }
  `;

  const FAQQuestion = styled.div`
    color: blue;
    text-transform: uppercase;
    font-size: 0.5em;
    line-height:1.2em;
  `;

  const ImageHero = styled.div`

    img {
    z-index:99;
    position: absolute;
      bottom: 0;
      right: 20px;
      width: 10em;
    }
  `;

  const ImageKittyPFP = styled.div`
    text-align:center;
    margin-top: 0.25em;

    img {
      width: 96px;
      border-radius: 30%;
      border: 3px solid black;
    }
  `;



  return (
    <Style.Section bg="#ff75c8">
      <Style.ScrollingSectionHeader bg="black">
        <Style.ScrollingContent>
          <h2>
            FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ /
            FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ / FAQ /
            FAQ / FAQ / FAQ / FAQ / FAQ /{" "}
          </h2>
        </Style.ScrollingContent>
      </Style.ScrollingSectionHeader>

      <FAQContainer>

       <ResponsiveMasonry
                columnsCountBreakPoints={{900: 1, 951: 2, 1400:3}}
            >
        <Masonry >

        <FAQItem>
          <FAQQuestion>What's the inspiration?</FAQQuestion>
          <p>Kibodo Kitty Club is a fun boutique onchain Ordinals collection that draws inspiration from Japanese art collectibles and retro-gaming aesthetic.</p>
          <p>The vibe is all about celebrating Bitcoin's next halvening and {HrefLink("opCat", "OP_CAT")}'s opcode integration with our chain's favorite pet... cats!</p>
          <p>This is also the first halvening with the Ordinals techtree unlocked, we are feeling gigabullish on this space. So why not memorialize all of these good vibes with a pixelated fluffy chonker?</p>
          <div style={{textAlign:"center"}}>
           <ResponsiveImage style={{marginTop:"0.5em", width:"50%"}} src={imgKittiesThumbsUp} />
           </div>
        </FAQItem>
        <FAQItem>
          <FAQQuestion>Elevator pitch?</FAQQuestion>
          <p>Animated.<br/>Fluffy.<br/>100% Onchain.<br/>Pixelated.<br/>Decentralized.<br/>Generative.<br/>Unfathomly Based.<br/>Chonkers.</p>
          <p>... dancing joyously for you!</p>
          <div style={{textAlign:"center"}}>
           <ResponsiveImage style={{marginTop:"0.25em"}} src={imgKittiesAnimatedHeaderRow} />
           </div>
        </FAQItem>

        

        <FAQItem>
          <FAQQuestion>Are these Ordinals fully onchain?</FAQQuestion>
          <p>Yes. All the metadata, encoded image layer bytecodes, traits/attributes, and the rendering engine are all inscribed fully onchain on the Bitcoin network. That means there is no reliance on any third-party storage solutions (i.e. IPFS, Arweave, etc). We just really really hate pointers OK?</p>
          <p>See The section above ("Rendering Engine") to learn about our unique onchain rendering technique.</p>
          <p>We believe in the decentralized future of art, and we'll die with the chain like real men.</p>
        </FAQItem>

        <FAQItem>
          <FAQQuestion>Roadmap?</FAQQuestion>
          <p>
            No roadmap.</p>
            <p>This collection is meant to be purely onchain art and a decentralized cultural artifact to celebrate the 2024 Bitcoin halvening and OP_CAT.</p>
            <p>No promises, no utility -- just gud art, gud tech and clean vibes.</p>

          <div style={{textAlign:"center"}}>
           <ResponsiveImage style={{marginTop:"0.25em"}} src={imgFAQRainbowGif} />
           </div>
          <p style={{textAlign:"center"}}>* Meowww~~ *</p>

        </FAQItem>

        <FAQItem>
          <FAQQuestion>What's the color scheme?</FAQQuestion>
          <p>Growing up playing tons of classic NES Japanese RPGs (Dragon Quest, Final Fantasy, Ultima, etc), we were inspired to utilize the <a href="https://lospec.com/palette-list/nintendo-entertainment-system" target="_blank">NES color scheme</a>, a classic palette of exactly 55 iconic colors:</p>
          <ResponsiveImage style={{marginTop:"0.25em"}} src={imgColorSchemeNES} />
        </FAQItem>

        <FAQItem>
          <FAQQuestion>What is "Kibodo"?</FAQQuestion>
          <p>
            "Kibodo" (<Style.Japanese>キーボード</Style.Japanese>) translates to "keyboard" in Japanese. Them furry lil chonks just love to jam on the keys.
          </p>
        </FAQItem>

         <FAQItem>
          <FAQQuestion>Why launch ordinals on Bitcoin?</FAQQuestion>
          <p>
            Having artwork stored fully onchain, truly decentralized and forever pegged to the OG mommy chain is incredibly based.</p>
          <p>Although I've been a long time BTC hodler, I've been disinterested in the BTC narrative for quite awhile. The lack of technical/cultural innovation was not interesting at all as an artist.</p>
          <p>But with the renewed focus around art and culture unlocked by <a href="https://docs.ordinals.com/introduction.html" target="_blank">Ordinal Theory</a>. We are stoked to contribute to the vibe and community with our collection.
          </p>
        </FAQItem>

        <FAQItem>
          <FAQQuestion>Chonkers? Why are you fat shaming cats?</FAQQuestion>
          <p>
            No shaming, but rather celebrating them.</p>
            <p>As an esteemed enjoyoooor of the <a href="https://www.reddit.com/r/Chonkers/">/r/chonkers</a> community, I've always found fat cats to be hilarious and weirdly intriguing. Perhaps there is some spiritual alignment with their fat lazy energy.
          </p>
           <ResponsiveImage style={{marginTop:"0.25em"}} src={imgFAQChonkers} />
        </FAQItem>

        <FAQItem>
          <FAQQuestion>How many variations are there?</FAQQuestion>
          <p>
            There are roughly 1.23 billion potential variations of unique chonkers thru our generative engine, which includes a mix of visual traits and colors.
          </p>
          <p>Some traits are rarer than others, and not all attributes are weighted equally.</p>

          <ResponsiveImage style={{marginTop:"0.25em"}} src={logoKittyMatrixAnimated} />
        </FAQItem>

        <FAQItem>
          <FAQQuestion>Are there rarities?</FAQQuestion>
          <p>
            Yes! Though we lovingly craft and kiss every pixel in the imageset regardless of rarity, we did indeed design some super rare traits.
          </p>
        </FAQItem>


        <FAQItem>
          <FAQQuestion>What is the visual output?</FAQQuestion>
          <p>Each Oridinal is rendered as an animated SVGs embedded in a light Javascript wrapper.</p>
          <p>Having the output format as SVGs allow the art to be completely lossless and infinitely scaleable (purrfect resolution). This means you can zoom-in infinitely into your chonker without any degradation in image quality.</p>
          <p>We also meticulously designed the framing of the output so that it also works well within a PFP format:</p>
          <ImageKittyPFP>
            <img src={imgKittyPfpExample} />
          </ImageKittyPFP>
        </FAQItem>

        <FAQItem>
          <FAQQuestion>How is the metadata stored?</FAQQuestion>
          <p>The metadata for the ordinals will be stored onchain by embedding the <a href="https://docs.ordinals.com/inscriptions/metadata.html" target="_blank">CBOR metadata</a> into the inscription. The CBOR metadata will be used to store all the traits and cattributes of each Ordinal.</p>
          <p>Read more about CBOR metadata <a href="https://docs.ordinals.com/inscriptions/metadata.html" target="_blank">here</a>.</p>
          
          </FAQItem>

          <FAQItem>
          <FAQQuestion>Is there provenance?</FAQQuestion>
          <p>Yes, each inscription will be created by the parent inscription. Meaning that each token within the official Kibodo Kitty Club Ordinals collection will be onchain children to the {HrefLink("inscriptionParent", "parent inscription")}.</p>
          <p>Read more about provenance <a href="https://docs.ordinals.com/inscriptions/provenance.html" target="_blank">here</a>.</p>
          <p>({HrefLink("inscriptionParent", "Inscription: Parent")})</p>
          </FAQItem>

        <FAQItem>
          <FAQQuestion>How do we stay connected?</FAQQuestion>
          <p>
            Follow {HrefLink("twitter", "@dailofrog")} or hang out with us on {HrefLink("discord", "Discord")}.
          </p>
        </FAQItem>

        <FAQItem>
          <FAQQuestion>Wen mint?</FAQQuestion>
          <p>
            Whitelisted mint will be on: April 11 at 11am PST.
          </p>
          <p>
            Public mint will be be 1pm PST (2-hour after WL).
          </p>
        </FAQItem>

        <FAQItem>
          <FAQQuestion>Whitelists?</FAQQuestion>
          <p>
            We are planning to provide whitelist to our OG collectors: {HrefLink("twitter", "Tiny Based Frogs")}, {HrefLink("twitter", "Memory Blocks")}, etc.</p>
          <p>
            The supply is limited and we are trying to not overallocate the whitelist above supply.</p>
            <p>Please join our {HrefLink("discord", "Discord")} for more info.
          </p>
        </FAQItem>

        {/*<FAQItem>
          <FAQQuestion>No Discord?</FAQQuestion>
          <p>
            We don't host Discords for any of our projects. I'm sorry to say but I find them to be generally cringe, especially when it comes to crypto or NFTs.
          </p>
          <p>We get that some people like to congregate there but after many years of intense Discord mental fatigue, we have decided to forego that platform. This may change in the future, but for now you may contact us on {HrefLink("twitter", "Twitter")} with any questions.
          </p>
          <p>You are now formally relieved from having to solve captchas, gm in the #gm channel, shitpost in #memes, and disabling @here notifications.</p>
          <ResponsiveImage style={{marginTop:"0.25em"}} src={imgFAQJapaneseNo} />
          <p style={{textAlign:"center"}}>"Gomennasai, no Discord."</p>
        </FAQItem>*/}

        <FAQItem style={{display:"none"}}>
          <FAQQuestion>Links</FAQQuestion>
          <p>Related links to this project:</p>

          <p>
            Mint Page:<br/>
              ◼ {HrefLink("tinyBasedFrogs", "Collection Bytecode Image Dataset")}
          </p>

          <p>
            Inscriptions (Recursive Modules):<br/>
              ◼ {HrefLink("tinyBasedFrogs", "Encoded Image Dataset")}
              <br/>
             ◼ {HrefLink("tinyBasedFrogs", "Render Engine")}
          </p>

          <p>
            Collection:<br/>
              ◼ {HrefLink("tinyBasedFrogs", "Magic Eden")}
              <br/>
             ◼ {HrefLink("tinyBasedFrogs", "Ordinal Hub")}
          </p>

          <p>
            Social:<br/>
              ◼ {HrefLink("twitter", "Twitter")}
              <br/>
             ◼ {HrefLink("linktree", "Linktree")}
          </p>
        </FAQItem>

        <FAQItem>
          <FAQQuestion>Disclaimer</FAQQuestion>
          <p>The digital artifacts, known as "Ordinals," associated with the Kibodo Kitty Club are purely artistic creations. They are not, and should not be considered as, investments or financial instruments. These digital artifacts are created and distributed for the enjoyment and appreciation of art and do not constitute securities in any form.</p>
          <p>We, the creators and distributors of the Kibodo Kitty Club Ordinals, are not responsible for any loss or damage, including but not limited to hacking or breaches of security, that may occur in relation to the digital artifacts. We make no guarantees regarding the security or stability of the underlying technology that supports these Ordinals.</p>
          <p> This disclaimer is to inform all users, collectors, and interested parties that the Kibodo Kitty Club digital artifacts are intended for artistic enjoyment and not for investment purposes. We aim to clarify that we are not liable for any unforeseen incidents affecting the digital artifacts, including technological vulnerabilities.</p>
        </FAQItem>

        </Masonry>
         </ResponsiveMasonry>
      </FAQContainer>

       <ImageHero>
        <img src={imgKittiesRowSmall} />
      </ImageHero>

      <Style.RotatedTextRight>
        <RotatedLineStart /> FREQUENTLY ASKED QUESTIONS /{" "}
        <Style.JapaneseSideways>キーボード</Style.JapaneseSideways>
      </Style.RotatedTextRight>
    </Style.Section>
  );
}

function RendererSection() {
  const ImageHero = styled.div`
    width: 20%;
    margin-right: 1.5em;.
    text-align:center;
    display: flex;
    justify-content: center; // Center flex items

    @media (max-width: 950px) {
      width: 100%;
      margin-bottom:1em;
    }

    img {
      max-width: 100%;
      height:auto;
      border-radius: 50%;

      @media (max-width: 950px) {
        width: 40%;
      }
    }

  `;

  const HeaderDescription = styled.div`
    width: 40%;
    text-align: center;

    @media (max-width: 950px) {
      width: 80%;
    }

    p {
      font-size: 0.5em;
      line-height: 1.3em;
    }

    h3 {
      font-weight: normal;
      font-size: 1.5em;
    }
  `;

  const StepContainer = styled.div`
    display: flex; /* Enables Flexbox */
    flex-wrap: wrap; /* Allows items to wrap onto the next line */
    justify-content: center; /* Aligns items to the start horizontally */
    align-items: flex-start; /* Aligns items to the start vertically */
    align-items: stretch; /* Stretch items to fill the container */
    align-content: stretch; /* Adjust spacing between lines */
    margin-top: 1em;
    gap: 0.5em;
    max-width: 80%; /

    //background-color:red;
  `;

  const StepImage = styled.div`

    text-align:center;
    margin-bottom:1.25em;

     img {
      max-width: 100%;
      height:auto;

    }
  `;

  const StepArrowImage = styled.div`
  background-color: red;
  border: 5px solid red;
    position:absolute;


  img {
    max-width: 100%;
    height: auto;
  }
  `;

  const StepItem = styled.div`
    /*background-color: #ffeb5f;
    color: black;
    box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 0.3);*/

     flex: 1; // Adjust the subtraction value based on the gap

    //margin: 10px;
    padding: 0.75em;
     box-sizing: border-box;
    font-size:0.5em;

    @media (max-width: 720px) {
      font-size:1em;
      width: 100%;
      margin: 10px;
      flex: 1;
    }

    p {
      margin-top: 20px;
      font-size: 0.5em;
      line-height: 1.3em;
    }

    h2 {
      text-align: center;
      font-size: 1.25em;
      color: blue;
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom:1.25em;
    }

    h3 {
      text-align: center;
      text-transform: uppercase;
      font-size: 1em;
      margin-bottom: 0.25em;
      font-weight: normal;
    }
  `;

  return (
    <Style.Section bg="#6dff85">
      <Style.ScrollingSectionHeader bg="blue">
        <Style.ScrollingContent>
          <h2>
            RENDERING / RENDERING / RENDERING / RENDERING / RENDERING /
            RENDERING / RENDERING / RENDERING / RENDERING / RENDERING /
            RENDERING / RENDERING / RENDERING / RENDERING / RENDERING /
            RENDERING /{" "}
          </h2>
        </Style.ScrollingContent>
      </Style.ScrollingSectionHeader>

      <Style.FixedHeightContainer height="auto" style={{ padding: "2.5em 0" }}>

        <HeaderDescription>
          <h3>RENDERING ENGINE</h3>

          <p style={{ marginTop: "20px"}}>
            Overview of the tech behind our onchain lossless renderer module.
          </p>
        </HeaderDescription>

        <StepContainer>

          <StepItem>
            <StepImage>
              <img src={imgStep1} />
            </StepImage>
            <h3>Step 1</h3>
            <h2>Encoding</h2>
            <p>We've developed custom technology to encode the entire collection's pixel art assets into an optimized set of bytecode instructions, tailored for performance in our custom rendering engine. This involves processing all pixel layers onto a single inscripted dataset, including trait information, color palette indices and precise rendering instructions.</p>
            <p>This dataset is fed into our custom rendering engine, designed as an inscripted module, to be used for the seamless reconstruction of the final animated artwork. All of this 100% fully onchain.</p>
            <p>({HrefLink("inscriptionEncodedDataset", "Inscription: Encoded Dataset")})</p>
          </StepItem>

          {/*<StepArrowImage>
            <img src={imgArrowRight} />
          </StepArrowImage>*/}

          <StepItem>
          <StepImage>
              <img src={imgStep2} />
            </StepImage>
            <h3>Step 2</h3>
            <h2>Inscription</h2>
            <p>When a user mints a Kibodo Ordinal, they inscribe a satoshi with a randomized seed. This seed will be used to determine the traits and to reconstruct the final SVG image.</p>
            <p>During mint, the user inscribes a small minified HTML document containing the seed (~310 total  bytes). All of the heavy lifting will be done via onchain recursive modules by referencing the rendering engine and bytecode dataset.</p>
            <p>The newly minted inscription will also be created as a child inscription of the parent, establishing provenance of the parent and inclusion within the official collection.</p>
            <p>({HrefLink("inscriptionParent", "Inscription: Parent")})</p>
          </StepItem>

          <StepItem>
          <StepImage>
              <img src={imgStep3} />
            </StepImage>
            <h3>Step 3</h3>
            <h2>Rendering</h2>
            <p>When a website (like Magic Eden) or a wallet requests to display this Ordinal, it executes our onchain generative rendering engine using the hashed seed stored within the inscription to determine its unique traits and rendering instructions.</p>
            <p>The engine deterministically reconstructs the image pixel-by-pixel by selecting weighted layers and emitting colored pixels as SVG rectangles.</p>
            <p>The final output image is an animated SVG -- which means that your chonkers are lossless, fully scaleable, and 100% onchain.</p>
            <p>({HrefLink("inscriptionRenderEngine", "Inscription: Render Engine")})</p>
          </StepItem>
        </StepContainer>
      </Style.FixedHeightContainer>

      <Style.RotatedTextLeft>
        <Style.JapaneseSideways>
          このカエルは非常にベースドです
        </Style.JapaneseSideways>
      </Style.RotatedTextLeft>

      <Style.RotatedTextRight>
        <RotatedLineStart /> NERD SHIT /{" "}
        <Style.JapaneseSideways>キーボード</Style.JapaneseSideways>
      </Style.RotatedTextRight>
    </Style.Section>
  );
}

function ArtistSection() {
  const ImageHero = styled.div`
    width: 20%;
    margin-right: 1.5em;.
    text-align:center;
    justify-content: center; // Center flex items
     align-items: center;

    width: 100%;
    margin-bottom:1em;

    img {
      max-width: 100%;
      height:auto;
      border-radius: 20%;
        width: 40%;

    }

  `;

  const ArtistSingle = styled.div`
    width: 40%;
    text-align:center;
    margin: 0 1em;

    @media (max-width: 950px) {
      width: 80%;
      margin: 2em 0;
    }
  `;

  const Description = styled.div`
    //background-color:red;



    p {
      font-size: 0.5em;
      line-height: 1.3em;
      //text-transform: uppercase;
      margin: 10px 0;
    }

    h3 {
      font-weight: normal;
      font-size: 1em;
    }

    a {
      color: blue;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  `;

  const Links = styled.div`
    border-top: 1px solid #ccc;
    padding-top: 20px;
    margin: 20px 0;
    //background-color:blue;
    font-size: 0.5em;
  `;

  const JapaneseFooter = styled.div`
    border-top: 1px solid #ccc;
    color: #ccc;
    padding-top: 5px;
    font-size: 12px;
  `;

  return (
    <Style.Section bg="white">
      <Style.ScrollingSectionHeader bg="blue">
        <Style.ScrollingContent>
          <h2>
            DEVELOPERS / DEVELOPERS / DEVELOPERS / DEVELOPERS / DEVELOPERS /
            DEVELOPERS / DEVELOPERS / DEVELOPERS / DEVELOPERS / DEVELOPERS /
            DEVELOPERS / DEVELOPERS / DEVELOPERS / DEVELOPERS / DEVELOPERS /
            DEVELOPERS /{" "}
          </h2>
        </Style.ScrollingContent>
      </Style.ScrollingSectionHeader>

      <Style.FixedHeightContainer height="auto" style={{ padding: "2em 0" }}>

        <ArtistSingle>
          <ImageHero>
            <img src={imgDailoFrog} />
          </ImageHero>
          <Description>
            <p>CREATIVE TECHNOLOGIST</p>
            <h3>DAILOFROG</h3>
            <Links>
              [ {HrefLink("twitter", "TWITTER")} / {HrefLink("linktree", "PORTFOLIO")} ]
            </Links>
            <p style={{ marginTop: "20px", marginBottom: "20px" }}>
              An onchain art maxi focusing on generative
              art and innovative interactive experiences. Creator of {HrefLink("memoryBlocks", "Memory Blocks")}, {HrefLink("tinyBasedFrogs", "Tiny Based Frogs")} and {HrefLink("linktree", "more")}.
            </p>

          </Description>
        </ArtistSingle>

         <ArtistSingle>
        <ImageHero>
          <img src={imgGoopGoop} />
        </ImageHero>
        <Description>
          <p>PIXEL ARTIST</p>
          <h3>GOOPGOOP</h3>
          <Links>
            [ {HrefLink("twitterGoop", "TWITTER")} ]
          </Links>
          <p style={{ marginTop: "20px", marginBottom: "20px" }}>
            OG industry veteran who has been drawing pixel art for literally decades. Created the artwork for {HrefLink("tinyBasedFrogs", "Tiny Based Frogs")}, {HrefLink("blastRocks", "Blast Rocks")} and more. Enjoys goopin' around.
          </p>

        </Description>
        </ArtistSingle>

      </Style.FixedHeightContainer>

      <Style.RotatedTextLeft>
        <Style.JapaneseSideways>
          このカエルは非常にベースドです
        </Style.JapaneseSideways>
      </Style.RotatedTextLeft>

      <Style.RotatedTextRight>
        <RotatedLineStart /> ONCHAIN ART /{" "}
        <Style.JapaneseSideways>キーボード</Style.JapaneseSideways>
      </Style.RotatedTextRight>
    </Style.Section>
  );
}

function FooterText() {
  const FooterStyle = styled.div`
    font-size: 0.5em;
    text-transform: uppercase;
    position: relative;
    top: -25px;
    color: #999;
    text-align: center;
  `;

  return (
    <FooterStyle>
      &copy; 2024 Dailofrog Technologies. All rights reserved.
    </FooterStyle>
  );
}

function LandingPageView() {
  return (
    <>
      <Style.Container>
        <Style.CenterColumn>
          <HeroSection />
          <AboutSection />
          <ExampleSection />
          <RendererSection />
          <FAQSection />
          <ArtistSection />
        </Style.CenterColumn>
      </Style.Container>
      <FooterText />
    </>
  );
}

export default LandingPageView;
