import styled, { keyframes }  from 'styled-components';

//import backgroundImage from '../images/bg.png'; // Replace with the path to your image
//import crossTilingBackground from '../assets/bg-cross.png'; // Replace with the path to your image


// SMALL: 320 - 640
// MEDIUM: 641 - 1007
// LARGE: 1008+

export const Japanese = styled.span`
  font-family: 'DotGothic16', monospace, sans-serif;
`;

export const JapaneseSideways = styled.span`
  font-family: 'DotGothic16', monospace, sans-serif;
  font-size:14px;
  font-weight:bold;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; // Center the columns if they are less than the container's width
`;

export const CenterColumn = styled.div`
  width:90%;
  min-width: 320px;
  max-width: 2400px;
  font-size:3em;

  @media (max-width: 2500px) {
    font-size:2em;
  }

  @media (max-width: 650px) {
    font-size:1em;
  }


  margin: 45px 10px;
  //border: 1px dashed #ccc;
  box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.03); /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
`;

export const Section = styled.div`
  background-color: ${props => props.bg || '#ccc'};
  position: relative;
`;


//////////////////////////////////////////

const scrollAnimation = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(-50%); }
`;


export const ScrollingSectionHeader = styled.div`
  background-color: ${props => props.bg || '#ccc'};
  overflow: hidden;
  width: 100%; // Adjust this to control the width of the scrolling area
  display:flex;
`;

export const ScrollingContent = styled.div`
  display: inline-flex;
  white-space: nowrap;
  animation: ${scrollAnimation} 30s linear infinite;

  h2 {
    color: white;
    font-weight: normal;
    font-size: 0.5em;
    padding: 0.5em 0;

    @media (max-width: 650px) {
       font-size:1em;
    }

  }
`;

//////////////////////////////////////////

export const Navbar = styled.div`
  //background-color:red;
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 0.5em;

  a {
    color: black;
    text-decoration: none;
  }

  a:hover{
    text-decoration: underline;
  }

  @media (max-width: 650px) {
     position: absolute;
    right: 0;     // Adjust right to 0
    left: 0;      // Add left to 0
    width: 100%;  // Set width to 100%
    //display: flex;
    //justify-content: center; // Center flex items
    text-align: center;
    font-size:0.75em
    }
`;



export const RotatedTextLeft = styled.div`
   position: absolute; 
    top: ${props => props.top || '50%'}; 
   left: 35px; /* 20px from the left edge of the container */
   transform: rotate(90deg) translateX(-50%) ; /* Adjust for centering and rotate */
   transform-origin: bottom left; /* Set the origin of transformation */
   white-space: nowrap; /* Prevent text wrapping */

   font-size: 0.5em;

   @media (max-width: 800px) {
      display:none;
    }
`;

export const RotatedTextRight = styled.div`
    position: absolute;
    top: ${props => props.top || '50%'};
    right: 50px; /* 20px from the right edge of the container */
    transform: rotate(90deg) translateX(50%); /* Adjust for centering and rotate */
    transform-origin: bottom right; /* Set the origin of transformation */
    white-space: nowrap; /* Prevent text wrapping */

    font-size: 0.5em;

    @media (max-width: 800px) {
      display:none;
    }
`;

export const FixedHeightContainer = styled.div`
    height:  ${props => props.height || '90vh'};
    min-height: fit-content;
    max-height: 2500px;
    display: flex; /* Enables Flexbox */
    justify-content: center; /* Centers content horizontally */
    align-items: center;

    flex-wrap: wrap;
`;


export const Button = styled.a`
  background-color:yellow;
  color: black!important;
  border: 0;
  border-right: 5px solid black;
  border-bottom: 5px solid black;
  display:block;

  font-size: 0.5em;
  width:60%;
  margin: 1em auto;
  padding: 1em 2em;
  text-align:center;

    &:hover {
      // Add your hover styles here
      background-color:teal; // Replace with the desired background color
      color: white!important; // Replace with the desired text color
      cursor: pointer; 
      text-decoration:none!important;
    }

    &:disabled {
      color: #333; // Replace with the desired text color
      background-color: var(--camo-300);
       cursor: not-allowed;
    }

`;
