// src/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';

import mouseIdle from '../assets/mouse/mouse-idle.png';
import mouseHover from '../assets/mouse/mouse-hover.png';


const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  body, button {
    background-color: #e0e0e0;
    font-family: 'Press Start 2P', monospace, sans-serif;
    font-kerning: none;
    text-rendering: optimizeSpeed;

    cursor: url(${mouseIdle}) 11 13, auto;

    a {
      cursor: url(${mouseHover}) 25 13, auto;
    }

    img {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }

  }
`;

export default GlobalStyle;
