import logo from './logo.svg';

import {
  createBrowserRouter,
  RouterProvider,
  useParams 
} from "react-router-dom";

import LandingPageView from './views/LandingPageView'
import GlobalStyle from './styles/GlobalStyle'

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPageView/>,
  },

  /*{
    path: "/show",
    element: <LandingPageView/>,
  },*/

  /*{
    path: "/about",
    element: <AboutPage/>,
  },*/
]);

function App() {
  return (
    <>
      <GlobalStyle />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
